import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import About from "../components/institucional/about/About";
import Policy from "../components/institucional/policy/Policy";

const InstitutionalPage = () => {
  return (
    <Layout>
      <SEO title="Institucional" />
      <About />
      <Policy />
    </Layout>
  )
}

export default InstitutionalPage;
