import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={100.003} height={61.698} {...props}>
      <g data-name="025-agreement">
        <g data-name="Group 61">
          <g data-name="Group 56">
            <path
              data-name="Path 290"
              d="M81.692 28.77l-2.54-20.636-4.76.586-9.229-5.194a5.561 5.561 0 00-3.239-.691l-14.57 1.342a5.56 5.56 0 00-4.3 2.743L32.783 24.598a2.443 2.443 0 001.249 3.513l.42.159a9.112 9.112 0 002.469.551l-1.3 1.133-2.6 2.268-7.405 6.317a3.164 3.164 0 00-.307 4.465l.751.86a4.066 4.066 0 005.735.392l2.83 3.246a4.059 4.059 0 005.735.392l2.827 3.246a4.068 4.068 0 005.739.394l1.536 1.328a4.068 4.068 0 005.739.394l2.089-1.822 17.558-13.675.019-.017 6.928-.007z"
              fill="#f29f5c"
            />
            <path
              data-name="Path 291"
              d="M74.168 32.428l-.019.017-17.558 13.677-2.089 1.822a4.068 4.068 0 01-5.739-.394l-1.539-1.328a4.068 4.068 0 01-5.739-.394l-2.827-3.246a4.059 4.059 0 01-5.735-.392l-2.83-3.246a4.066 4.066 0 01-5.035.24 3.163 3.163 0 00.251 3.825l.751.86a4.066 4.066 0 005.735.392l2.83 3.246a4.059 4.059 0 005.735.392l2.827 3.247a4.068 4.068 0 005.739.394l1.536 1.328a4.068 4.068 0 005.739.394l2.089-1.822 17.558-13.675.019-.017 6.928-.007-.766-6.226z"
              fill="#d37d42"
            />
          </g>
          <g data-name="Group 57">
            <path
              data-name="Path 292"
              d="M77.365 41.353l-9.407-8.112-2.616-2.254-1.872-1.612-8.048-6.938.93-7.912a5.46 5.46 0 00-3.967-5.9L34.839 3.771a4.06 4.06 0 00-4.7 2.062l-2.206 4.307-9.471-.643-2.426 20.65-1.054 8.974 9.219.613.019.017 19.259 16.6 2.1 1.811a4.068 4.068 0 005.736-.426l2.809-3.259a4.068 4.068 0 005.737-.426l2.809-3.262a4.059 4.059 0 005.733-.423l2.812-3.262a4.066 4.066 0 005.733-.423l.747-.865a3.163 3.163 0 00-.33-4.463z"
              fill="#f9c295"
            />
            <path
              data-name="Path 293"
              d="M77.365 41.352l-2.866-2.471a3.359 3.359 0 01-.139 4.212l-.792.918a4.316 4.316 0 01-6.086.449l-2.985 3.463a4.309 4.309 0 01-6.086.449l-2.982 3.463a4.318 4.318 0 01-6.09.452l-2.981 3.465a4.288 4.288 0 01-2.046 1.319l1.269 1.1a4.068 4.068 0 005.736-.426l2.809-3.259a4.068 4.068 0 005.737-.426l2.809-3.262a4.059 4.059 0 005.733-.423l2.812-3.262a4.066 4.066 0 005.733-.423l.746-.865a3.164 3.164 0 00-.331-4.473z"
              fill="#e59f6a"
            />
          </g>
          <g data-name="Group 59">
            <g data-name="Group 58" fill="#f29f5c">
              <path
                data-name="Path 294"
                d="M24.685 39.713l-1.116 1.473a3.645 3.645 0 00.705 5.106 3.645 3.645 0 005.106-.705l1.116-1.473a3.645 3.645 0 00-.705-5.106 3.645 3.645 0 00-5.106.705z"
              />
              <path
                data-name="Path 295"
                d="M31.482 42.814l-2.1 2.775a3.645 3.645 0 00.705 5.106 3.645 3.645 0 005.106-.705l2.1-2.775a3.645 3.645 0 00-.705-5.106 3.645 3.645 0 00-5.106.705z"
              />
              <path
                data-name="Path 296"
                d="M38.054 46.205l-2.864 3.782a3.645 3.645 0 00.705 5.106 3.645 3.645 0 005.106-.705l2.864-3.782a3.645 3.645 0 00-.705-5.106 3.645 3.645 0 00-5.106.705z"
              />
              <path
                data-name="Path 297"
                d="M44.031 50.387l-3.03 4a3.645 3.645 0 00.705 5.106 3.645 3.645 0 005.106-.705l3.03-4a3.645 3.645 0 00-.705-5.106 3.645 3.645 0 00-5.106.705z"
              />
            </g>
          </g>
          <path
            data-name="Path 298"
            d="M74.395 8.716l-9.229-5.194a5.561 5.561 0 00-3.239-.691L47.355 4.176a5.56 5.56 0 00-4.3 2.743L32.784 24.597a2.443 2.443 0 001.249 3.513l.42.159a9.132 9.132 0 0010.468-2.979l3.935-5.121s9.459 10.952 19.873-1.838"
            fill="#f29f5c"
          />
          <path
            data-name="Path 299"
            d="M93.71 1.465l4.827 39.21-11.287 1.389a3.892 3.892 0 01-4.338-3.387L79.036 7.192a3.892 3.892 0 013.387-4.338z"
            fill="#fc0"
          />
          <path
            data-name="Path 300"
            d="M87.062 37.64a4.267 4.267 0 01-4.682-3.291l.533 4.329a3.892 3.892 0 004.338 3.387l11.287-1.389-.539-4.382z"
            fill="#c59f04"
          />
          <path
            data-name="Path 301"
            d="M6.292 1.465l-4.828 39.21 11.287 1.39a3.892 3.892 0 004.338-3.387l3.875-31.486a3.892 3.892 0 00-3.387-4.338z"
            fill="#ed5575"
          />
          <path
            data-name="Path 302"
            d="M13.018 37.542l-11-1.354-.554 4.487 11.287 1.389a3.892 3.892 0 004.338-3.387l.409-3.326a4.572 4.572 0 01-4.48 2.191z"
            fill="#cc3a60"
          />
          <g
            data-name="Group 60"
            transform="translate(6.385 31.386)"
            fill="#ebeef2"
          >
            <circle
              data-name="Ellipse 10"
              cx={2.832}
              cy={2.832}
              r={2.832}
              transform="translate(81.411)"
            />
            <circle data-name="Ellipse 11" cx={2.832} cy={2.832} r={2.832} />
          </g>
        </g>
        <g data-name="Group 62">
          <path
            data-name="Path 303"
            d="M50.021 48.514a5.1 5.1 0 00-4.02-.947 5.107 5.107 0 00-5.74-4.224 5.165 5.165 0 00-.973.232 5.1 5.1 0 00-6.852-3.284 5.111 5.111 0 00-8.413-2.039l-5.362-.356 3.286-26.7 5.889.4h.1a1.465 1.465 0 001.3-.8l2.207-4.306a2.606 2.606 0 013-1.317l3.277.906a1.465 1.465 0 00.781-2.824l-3.277-.906a5.547 5.547 0 00-6.39 2.8l-1.767 3.448-4.766-.324.113-.917a5.362 5.362 0 00-4.662-5.971L6.471.011a1.465 1.465 0 00-.358 2.908l11.288 1.39a2.427 2.427 0 012.112 2.705l-3.876 31.485a2.43 2.43 0 01-2.705 2.112l-11.287-1.39a1.465 1.465 0 00-.358 2.908l11.287 1.389a5.351 5.351 0 005.347-2.734l4.011.267a5.11 5.11 0 004.533 7.446 5.209 5.209 0 00.71-.049l.045-.007c0 .015 0 .03.005.046a5.1 5.1 0 005.055 4.408 5.153 5.153 0 00.752-.057 5.1 5.1 0 005.059 4.458 5.148 5.148 0 00.75-.057c0 .016 0 .033.005.049a5.112 5.112 0 005.051 4.411 5.207 5.207 0 00.71-.049 5.076 5.076 0 003.374-1.977l3.03-4a5.115 5.115 0 00-.99-7.159zm-23.248-2.971a2.18 2.18 0 01-2.035-3.475l1.116-1.473a2.18 2.18 0 013.475 2.632L28.213 44.7a2.166 2.166 0 01-1.44.843zm4.2 3.979a2.179 2.179 0 01-.421-3.053l1.116-1.473.985-1.3a2.18 2.18 0 013.475 2.632l-2.1 2.775a2.182 2.182 0 01-3.058.422zm5.811 4.4a2.18 2.18 0 01-.422-3.053l2.1-2.775.763-1.007a2.18 2.18 0 013.476 2.631l-2.865 3.783a2.182 2.182 0 01-3.056.424zm11.894-.022l-3.03 4a2.18 2.18 0 11-3.475-2.632l2.864-3.782.165-.217a2.18 2.18 0 113.475 2.632z"
          />
          <path
            data-name="Path 304"
            d="M99.992 40.496a1.465 1.465 0 00-1.633-1.275L87.072 40.61a2.429 2.429 0 01-2.705-2.112l-.116-.94-.027-.218-3.733-30.328a2.427 2.427 0 012.112-2.705L93.89 2.918A1.465 1.465 0 1093.532.01L82.245 1.399a5.363 5.363 0 00-4.7 5.455l-2.846.35-8.814-4.96a7 7 0 00-4.092-.874h-.009l-2.653.245a1.465 1.465 0 00.27 2.917l2.66-.246a4.078 4.078 0 012.386.509l9.139 5.147a1.465 1.465 0 00.986.232l3.306-.407 2.425 19.7.838 6.812h-5.273a1.464 1.464 0 00-.956.356l-.408.318-13.238-11.411c3.025-.87 5.909-2.974 8.605-6.285a1.465 1.465 0 10-2.272-1.85c-3.014 3.7-6.183 5.606-9.419 5.662-4.714.089-8.178-3.82-8.21-3.857a1.465 1.465 0 00-2.27.065l-3.935 5.121a7.623 7.623 0 01-8.789 2.5l-.42-.159a.978.978 0 01-.5-1.406L44.327 7.655a4.1 4.1 0 013.164-2.021l6.081-.562a1.465 1.465 0 10-.27-2.917l-6.081.562a7.038 7.038 0 00-5.427 3.466L31.523 23.861a3.908 3.908 0 002 5.619l.42.159a10.672 10.672 0 003.776.694 10.536 10.536 0 008.372-4.151l2.936-3.821a14.467 14.467 0 008.255 3.611l19.13 16.488a1.7 1.7 0 01.179 2.4l-.746.865a2.607 2.607 0 01-3.67.269 1.464 1.464 0 00-.192-.138l-4.966-4.282a1.465 1.465 0 00-1.913 2.219l4.036 3.48-1.841 2.136a2.6 2.6 0 01-3.668.271l-.022-.017-4.614-3.979a1.465 1.465 0 10-1.913 2.219l3.524 3.039-1.852 2.151a2.6 2.6 0 01-2.278.887 1.465 1.465 0 00-.342 2.91 5.532 5.532 0 004.84-1.884l1.98-2.3a5.528 5.528 0 006.563-1.386l1.983-2.3a5.544 5.544 0 006.562-1.382l.746-.866a4.629 4.629 0 00-.485-6.528l-1.2-1.036h4.395a5.352 5.352 0 005.915 4.315l11.287-1.389a1.465 1.465 0 001.272-1.638z"
          />
          <path
            data-name="Path 305"
            d="M90.627 29.921a4.3 4.3 0 104.3 4.3 4.3 4.3 0 00-4.3-4.3zm0 5.664a1.367 1.367 0 111.367-1.367 1.369 1.369 0 01-1.367 1.367z"
          />
          <path
            data-name="Path 306"
            d="M13.514 34.218a4.3 4.3 0 10-4.3 4.3 4.3 4.3 0 004.3-4.3zm-5.663 0a1.367 1.367 0 111.367 1.367 1.368 1.368 0 01-1.367-1.367z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent

