import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={100.679} height={63.686} {...props}>
      <g data-name="021-truck" transform="translate(0 -94.062)">
        <path
          data-name="Path 249"
          d="M70.517 95.638H9.981A1.682 1.682 0 008.3 97.319v35.311h5.044a3.363 3.363 0 110 6.726H8.302v5.044a1.682 1.682 0 001.681 1.681h62.215V97.319a1.682 1.682 0 00-1.681-1.681z"
          fill="#b4e66e"
        />
        <path
          data-name="Path 250"
          d="M92.377 122.544H72.199v23.541h26.9v-16.817a6.726 6.726 0 00-6.722-6.724z"
          fill="#ffdc64"
        />
        <path
          data-name="Path 251"
          d="M72.199 127.587h18.216a3.363 3.363 0 013.363 3.363v15.133H72.199z"
          fill="#ffc850"
        />
        <path
          data-name="Path 252"
          d="M85.651 102.365H72.199v6.726h18.5v-1.681a5.045 5.045 0 00-5.048-5.045z"
          fill="#ffdc64"
        />
        <path
          data-name="Path 253"
          d="M92.377 122.543l-3.363-13.452H72.199v13.452z"
          fill="#b4e6ff"
        />
        <path
          data-name="Path 254"
          d="M92.377 129.266h6.726v6.726H95.74a3.363 3.363 0 01-3.363-3.363v-3.363z"
          fill="#f1f4fb"
        />
        <path
          data-name="Path 255"
          d="M83.969 134.313a13.444 13.444 0 00-11.77 6.945v4.826h25.106a13.446 13.446 0 00-13.336-11.771z"
          fill="#e1a546"
        />
        <path
          data-name="Path 256"
          d="M93.774 138.571v7.512h3.527a13.419 13.419 0 00-3.527-7.512z"
          fill="#ffc850"
        />
        <circle
          data-name="Ellipse 8"
          cx={8.408}
          cy={8.408}
          r={8.408}
          transform="translate(75.562 139.357)"
          fill="#5b5d6e"
        />
        <path
          data-name="Path 257"
          d="M78.925 114.135h11.35l-1.261-5.044H72.199v13.452h5.044v-6.726a1.681 1.681 0 011.682-1.682z"
          fill="#9bd6ff"
        />
        <path
          data-name="Path 258"
          d="M28.481 134.313a13.418 13.418 0 00-10.493 5.044H8.302v5.044a1.682 1.682 0 001.681 1.681h62.215v-6.725H38.972a13.419 13.419 0 00-10.491-5.044z"
          fill="#a0d755"
        />
        <circle
          data-name="Ellipse 9"
          cx={8.408}
          cy={8.408}
          r={8.408}
          transform="translate(20.073 139.357)"
          fill="#5b5d6e"
        />
        <path
          data-name="Path 259"
          d="M93.631 121.061l-2.607-10.429a1.577 1.577 0 001.248-1.541v-1.681a6.628 6.628 0 00-6.621-6.621H73.776V97.32a3.262 3.262 0 00-3.258-3.258H9.984a3.262 3.262 0 00-3.258 3.258v28.585a1.576 1.576 0 103.153 0V97.32a.1.1 0 01.105-.1h60.533a.1.1 0 01.105.1v28.586a1.576 1.576 0 103.153 0v-1.787h18.6a5.158 5.158 0 014.9 3.573h-4.9a1.576 1.576 0 00-1.576 1.576v3.363a4.945 4.945 0 004.939 4.939h1.787v6.936H93.4a9.981 9.981 0 00-18.87 0h-.759v-11.875a1.576 1.576 0 00-3.153 0v11.875H37.915a9.981 9.981 0 00-18.87 0H9.984a.1.1 0 01-.105-.1v-3.468h6.831a1.576 1.576 0 000-3.153H1.576a1.576 1.576 0 000 3.153h5.15v3.462a3.262 3.262 0 003.258 3.258H18.5v.1a9.984 9.984 0 1019.968 0v-.1h35.52v.1a9.984 9.984 0 1019.968 0v-.1H99.1a1.577 1.577 0 001.576-1.576v-16.814a8.315 8.315 0 00-7.045-8.207zm-19.855-10.394h14.007l2.575 10.3H73.776v-10.3zm11.875-6.726a3.472 3.472 0 013.468 3.468v.105H73.776v-3.573zM28.48 154.6a6.831 6.831 0 116.831-6.831 6.839 6.839 0 01-6.831 6.831zm55.489 0a6.831 6.831 0 116.831-6.831 6.839 6.839 0 01-6.83 6.831zm11.77-20.178a1.789 1.789 0 01-1.787-1.787v-1.787h3.573v3.573H95.74z"
        />
        <path
          data-name="Path 260"
          d="M28.48 144.51a3.258 3.258 0 103.258 3.258 3.262 3.262 0 00-3.258-3.258z"
        />
        <path
          data-name="Path 261"
          d="M83.97 144.51a3.258 3.258 0 103.258 3.258 3.262 3.262 0 00-3.258-3.258z"
        />
        <path
          data-name="Path 262"
          d="M40.356 139.357a1.576 1.576 0 001.576 1.576h23.541a1.577 1.577 0 100-3.153H41.928a1.576 1.576 0 00-1.572 1.577z"
        />
        <path
          data-name="Path 263"
          d="M4.939 134.208h20.178a1.577 1.577 0 100-3.153H4.939a1.577 1.577 0 100 3.153z"
        />
        <path
          data-name="Path 264"
          d="M52.588 109.657L38.57 123.672l-7.293-7.293a1.576 1.576 0 10-2.229 2.229l8.408 8.408a1.576 1.576 0 002.229 0l15.131-15.13a1.576 1.576 0 10-2.229-2.229z"
        />
      </g>
    </svg>
  )
}

export default SvgComponent

