import React, { useEffect } from 'react';
import HighFiveIcon from '../../shared/icons/institutional/HighFiveIcon';
import HandshakeIcon from '../../shared/icons/institutional/HandshakeIcon';
import DeliveryTruckIcon from '../../shared/icons/institutional/DeliveryTruckIcon';
import './Policy.scss';

const Policy = () => {
  useEffect(() => {
    const pillars = document.querySelectorAll('.policy__pillar__single');
    const header = document.querySelector('.policy__header');
    const text = document.querySelector('.policy__text');

    const appearOnScroll = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) return;
        else {
          entry.target.style.animation
            = `fadeInUp 1s ease-in-out ${entry.target.dataset.delay ? entry.target.dataset.delay : ''} forwards`;
        }
      })
    }, { threshold: 0.75 });

    appearOnScroll.observe(text);
    appearOnScroll.observe(header);

    pillars.forEach(elem => {
      appearOnScroll.observe(elem);
    });
  });

  return (
    <section className="policy">
      <div className="container">
        <div className="policy__header">
          Política de qualidade
        </div>
        <div className="policy__description">
          <div className="policy__text">
            A empresa vem crescendo continuamente por acreditar e investir sempre em seu maior lema, <span>Sempre com Você</span>, mantendo estreitos e sólidos laços de relacionamento com clientes e fornecedores.
          </div>
          <div className="policy__pillar">
            <div className="policy__pillar__single" data-delay="0.1s">
              <HighFiveIcon />
              <span className="policy__pillar__single__title">Colaboradores preparados</span>
              <span className="policy__pillar__single__text">
                Manter colaboradores internos e externos treinados e satisfeitos
              </span>
            </div>
            <div className="policy__pillar__single" data-delay="0.4s">
              <HandshakeIcon />
              <span className="policy__pillar__single__title">Fornecedores comprometidos</span>
              <span className="policy__pillar__single__text">
                Contar com fornecedores comprometidos com parceria e com o desenvolvimento da qualidade
              </span>
            </div>
            <div className="policy__pillar__single" data-delay="0.7s">
              <DeliveryTruckIcon />
              <span className="policy__pillar__single__title">Trabalhar organizadamente</span>
              <span className="policy__pillar__single__text">
                Atendemos as expectativas dos clientes oferecendo rápido e eficiente atendimento aos mesmos através do SAC
              </span>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default Policy;
