import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={106.522} height={98.2} {...props}>
      <g data-name="019-relationship">
        <path
          data-name="Path 239"
          d="M51.44 33.653L28.087 57.005H13.628A11.547 11.547 0 002.081 68.552v27.567h33.288V70.332L48.981 50.11a25.076 25.076 0 004.276-14.006v-1.7a1.067 1.067 0 00-1.817-.751z"
          fill="#72d8ff"
        />
        <path
          data-name="Path 240"
          d="M92.895 57.006h-14.46L55.082 33.653a1.067 1.067 0 00-1.821.754v1.7a25.076 25.076 0 004.276 14.006l13.616 20.222v25.787h23.3v-2.725l1.2 2.718h8.79V68.553a11.547 11.547 0 00-11.548-11.547z"
          fill="#fc0"
        />
        <g data-name="Group 53" fill="#fcc69d">
          <path
            data-name="Path 241"
            d="M23.717 37.033a8.518 8.518 0 00-8.518 8.518v2.936a8.518 8.518 0 008.518 8.518 8.518 8.518 0 008.518-8.518v-2.936a8.518 8.518 0 00-8.518-8.518z"
          />
          <path
            data-name="Path 242"
            d="M82.803 37.033a8.518 8.518 0 018.518 8.518v2.936a8.518 8.518 0 01-8.518 8.518 8.518 8.518 0 01-8.518-8.518v-2.936a8.518 8.518 0 018.518-8.518z"
          />
        </g>
        <path
          data-name="Path 243"
          d="M53.259 16.539a2.081 2.081 0 002.081-2.081V2.081a2.081 2.081 0 00-4.161 0v12.377a2.081 2.081 0 002.08 2.081z"
        />
        <path
          data-name="Path 244"
          d="M38.529 20.707a2.08 2.08 0 002.941-2.943l-4.978-4.973a2.081 2.081 0 10-2.941 2.94z"
        />
        <path
          data-name="Path 245"
          d="M66.208 21.644a2.074 2.074 0 001.472-.61l5.292-5.3a2.081 2.081 0 00-2.944-2.941l-5.292 5.3a2.081 2.081 0 001.472 3.551z"
        />
        <path
          data-name="Path 246"
          d="M92.895 54.926h-1.679a10.543 10.543 0 002.184-6.438v-2.936a10.6 10.6 0 10-21.2 0v2.283L56.554 32.182a3.136 3.136 0 00-3.292-.733 3.137 3.137 0 00-3.293.733L34.317 47.834v-2.283a10.6 10.6 0 00-21.2 0v2.936a10.542 10.542 0 002.187 6.438h-1.677A13.643 13.643 0 000 68.553V96.12a2.081 2.081 0 002.081 2.081h8.79a2.08 2.08 0 00.6-.088 2.08 2.08 0 00.6.088h23.3a2.081 2.081 0 002.081-2.081v-7.074a2.081 2.081 0 10-4.161 0v4.993H14.147V70.332a2.081 2.081 0 10-4.161 0v23.707H4.161V68.553a9.477 9.477 0 019.466-9.466h14.46a2.074 2.074 0 001.467-.606l21.61-21.61a22.909 22.909 0 01-3.904 12.077L33.643 69.17a2.082 2.082 0 00-.355 1.162v2.276a2.081 2.081 0 104.161 0v-1.643l13.262-19.7a27.23 27.23 0 002.55-4.745 27.216 27.216 0 002.55 4.745l13.262 19.7v25.152a2.081 2.081 0 002.081 2.081h23.3a2.08 2.08 0 00.6-.088 2.08 2.08 0 00.6.088h8.79a2.081 2.081 0 002.081-2.081V68.553a13.643 13.643 0 00-13.63-13.627zM17.28 48.488v-2.936a6.438 6.438 0 1112.875 0v2.936a6.438 6.438 0 11-12.875 0zm59.086-2.936a6.438 6.438 0 0112.875 0v2.936a6.438 6.438 0 11-12.875 0zM102.36 94.04h-5.825v-15.4a2.081 2.081 0 00-4.161 0v15.4h-19.14V70.332a2.081 2.081 0 00-.355-1.162L59.263 48.949a22.909 22.909 0 01-3.909-12.081l21.61 21.61a.967.967 0 00.078.071 2.07 2.07 0 001.393.538h14.46a9.477 9.477 0 019.466 9.466v25.486z"
        />
        <path
          data-name="Path 247"
          d="M94.455 68.251a2.082 2.082 0 101.471.61 2.1 2.1 0 00-1.471-.61z"
        />
        <path
          data-name="Path 248"
          d="M35.369 79.267a2.082 2.082 0 101.471.61 2.1 2.1 0 00-1.471-.61z"
        />
      </g>
    </svg>
  )
}

export default SvgComponent

