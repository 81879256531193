import React, { useEffect } from 'react';
import './About.scss';

const About = () => {

  useEffect(() => {
    const content = document.querySelector('.about__content');

    const appearOnScrollLeft = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) return;
        else {
          entry.target.style.animation = `fadeInLeft 1s ease-in-out forwards`;
        }
      })
    }, { threshold: 0.5 });

    appearOnScrollLeft.observe(content);

    const photo = document.querySelector('.about__photo');
    const placeholder = document.querySelector('.marbel__photo');

    placeholder.addEventListener('load', () => {
      photo.style.animation = `fadeInRight 1s ease-in-out forwards`;
    });
  });

  return (
    <div className="container">
      <section className="about">
        <div className="about__content">
          <div className="about__content__title">
            Nossa história
          </div>
          <div className="about__content__text">
            A Marbel Plásticos iniciou suas atividades em 2004, em Valinhos no interior de São Paulo
            e atualmente está instalada no distrito industrial de Vinhedo, reconhecido centro tecnológico,
            próximo a importantes rodovias do estado de São Paulo, facilitando o rápido escoamento de sua
            produção.
            <br />
            <br />
            Preocupada em aliar qualidade à praticidade, produz uma linha variada que de itens
            de diversos segmentos, desde utensílios de mesa e cozinha, artigos de limpeza, organizadores
            até brinquedos. Reforçando a preocupação da empresa com bom uso do meio ambiente e a
            preservação do mesmo, seus colaboradores são sempre treinados e orientados a reutilizarem
            as sobras dos processos produtivos.
          </div>
        </div>
        <div className="about__photo">
          <img
            className="marbel__photo"
            width="325px"
            src="https://marbel-plasticos.s3-sa-east-1.amazonaws.com/background/marbel_15anos.jpg"
            alt="Marbel Plásticos" />
        </div>
      </section>
    </div>
  )
}

export default About;
